<template>
  <AtomButton
    v-if="showLoadMore"
    button-type="button"
    class="atom-load-more"
    :is-disabled="isLoading"
    :text="buttonText"
    @click=" emit('load-more')"
    @keypress=" emit('load-more')"
  />
</template>

<script setup>
const props = defineProps({
    maxItemAmount: {
        type: Number,
        default: 0,
    },
    currentItemAmount: {
        type: Number,
        default: 0,
    },
    isLoading: {
        type: Boolean,
        default: false,
    },
    buttonText: {
        type: String,
        default: '',
    },
});

const showLoadMore = computed(() => props.currentItemAmount < props.maxItemAmount);
const emit = defineEmits('load-more');
</script>
